module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/usr/src/app/www/node_modules/gatsby-remark-images-anywhere","id":"529add1b-b03b-594c-814e-14be9e70108e","name":"gatsby-remark-images-anywhere","version":"1.2.1","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"maxWidth":1024,"linkImagesToOriginal":false,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tameto-gatsby","short_name":"tameto","start_url":"/","background_color":"#F6D98E","theme_color":"#3B4566","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
